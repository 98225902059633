.tip {
    width: 85%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem;
}

.tip button {
    width: 100%
}

.tip input {
    width: 90%
}